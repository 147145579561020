<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    :class="classname"
  >
    <path d="M9.50006 11V17H11.5001V11H17.5V9H11.5001V3H9.50006V9H3.5V11H9.50006Z" />
  </svg>
</template>

<script>
export default {
  name: 'Add',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
