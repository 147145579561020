<template>
  <div class="">
      <a :href="redirectUrl" class="flex h-10 items-center">
          <IconButton
            v-tooltip.right="'Log in'"
            classname="w-9 h-9 group"
            :variant="'normal'"
            size="normal"
          >
            <div class="relative">
              <Login classname="w-5 h-5  group-hover:hidden" />
              <LoginFilled
                classname="w-5 h-5 group-hover:text-red hidden group-hover:inline-block"
              />
            </div>
          </IconButton>
      </a>
  </div>
</template>

<script>
import Login from '@/components/base/icons/Login.vue'
import LoginFilled from '@/components/base/icons/LoginFilled.vue'
import IconButton from '@/components/base/buttons/IconButton.vue'

export default {
  name: 'LoginButton',
  components: {
    IconButton,
    Login,
    LoginFilled
  },
  data() {
    return {
    }
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    redirectUrl() {
      if (this.$router.currentRoute.name === 'index') return
      return '/?redirect=' + window.location.pathname
    }
  }
}
</script>
