<template>
  <v-popover v-bind="$attrs" v-on="$listeners">
    <slot />

    <template slot="popover">
      <slot name="popover"/>
    </template>
  </v-popover>
</template>

<script>
export default {

}
</script>

<style>

</style>
