<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      d="M7.19409 6.68384V3.18384M14.1941 6.68384V3.18384M6.31909 10.1838H15.0691M4.56909 18.9338H16.8191C17.7856 18.9338 18.5691 18.1503 18.5691 17.1838V6.68384C18.5691 5.71734 17.7856 4.93384 16.8191 4.93384H4.56909C3.60259 4.93384 2.81909 5.71734 2.81909 6.68384V17.1838C2.81909 18.1503 3.60259 18.9338 4.56909 18.9338Z"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  stroke: currentColor;
}
svg > path {
  stroke: currentColor;
}
</style>
