<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3875 1.28095C11.8973 -0.20917 9.4813 -0.209169 7.99113 1.28095L4.34142 4.93053L4.34186 4.93097L3.50316 5.76968C2.38552 6.88732 2.38552 8.69937 3.50315 9.817L3.73879 10.0526L2.39161 11.0052C0.627967 12.2522 0.41207 14.7869 1.93941 16.3142C3.46674 17.8416 6.00143 17.6257 7.24846 15.862L8.20101 14.5149L8.43666 14.7505C9.55429 15.8681 11.3663 15.8681 12.484 14.7505L14.6713 12.5632L14.6709 12.5627L16.9719 10.2617C18.4621 8.77146 18.462 6.35538 16.9718 4.86521L13.3875 1.28095ZM9.34021 2.63008C10.0853 1.88502 11.2933 1.88502 12.0384 2.63008L15.6227 6.21434C16.3679 6.95942 16.3679 8.16746 15.6228 8.91256L13.3222 11.2131L7.03965 4.93055L9.34021 2.63008ZM4.85226 7.11879L5.69049 6.28056L11.9731 12.5632L11.1349 13.4014C10.7623 13.7739 10.1583 13.7739 9.78576 13.4014L9.15003 12.7657C8.52281 12.1384 7.48192 12.2271 6.96982 12.9514L5.69062 14.7605C5.1264 15.5585 3.97956 15.6562 3.28851 14.9651C2.59746 14.2741 2.69515 13.1273 3.49312 12.563L5.30228 11.2838C6.02654 10.7717 6.11519 9.73083 5.48798 9.10361L4.85226 8.4679C4.47972 8.09535 4.47972 7.49134 4.85226 7.11879Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BrandKit',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
