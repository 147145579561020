<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      d="M10 11H13.5858L12.2929 9.70711C11.9024 9.31658 11.9024 8.68342 12.2929 8.29289C12.6834 7.90237 13.3166 7.90237 13.7071 8.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L13.7071 15.7071C13.3166 16.0976 12.6834 16.0976 12.2929 15.7071C11.9024 15.3166 11.9024 14.6834 12.2929 14.2929L13.5858 13H10V16.8385C9.99999 17.3657 9.99997 17.8205 10.0306 18.195C10.0629 18.5904 10.1342 18.9836 10.327 19.362C10.6146 19.9265 11.0735 20.3854 11.638 20.673C12.0164 20.8658 12.4096 20.9371 12.805 20.9694C13.1795 21 13.6343 21 14.1615 21H16.8385C17.3657 21 17.8205 21 18.195 20.9694C18.5904 20.9371 18.9836 20.8658 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C20.8658 18.9836 20.9371 18.5904 20.9694 18.195C21 17.8205 21 17.3657 21 16.8386V7.16148C21 6.63434 21 6.17954 20.9694 5.80497C20.9371 5.40963 20.8658 5.01641 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.9836 3.13419 18.5904 3.06287 18.195 3.03057C17.8205 2.99997 17.3657 2.99998 16.8386 3H14.1614C13.6343 2.99998 13.1795 2.99997 12.805 3.03057C12.4096 3.06287 12.0164 3.13419 11.638 3.32698C11.0735 3.6146 10.6146 4.07355 10.327 4.63803C10.1342 5.01641 10.0629 5.40963 10.0306 5.80498C9.99997 6.17953 9.99998 6.63432 10 7.16143V11Z"
      s
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 12C3 12.5523 3.44772 13 4 13H10V11H4C3.44772 11 3 11.4477 3 12Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LoginFilled',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
