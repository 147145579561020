<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.94412 5.54883C6.42737 5.54883 6.81912 5.94058 6.81912 6.42383V13.5988C6.81912 14.3483 6.8198 14.8578 6.85198 15.2517C6.88332 15.6353 6.94013 15.8315 7.00986 15.9683C7.17764 16.2976 7.44536 16.5653 7.77464 16.7331C7.91149 16.8028 8.10767 16.8596 8.49128 16.891C8.88511 16.9231 9.39462 16.9238 10.1441 16.9238H12.2441C12.9936 16.9238 13.5031 16.9231 13.897 16.891C14.2806 16.8596 14.4768 16.8028 14.6136 16.7331C14.9429 16.5653 15.2106 16.2976 15.3784 15.9683C15.4481 15.8315 15.5049 15.6353 15.5363 15.2517C15.5684 14.8578 15.5691 14.3483 15.5691 13.5988V6.42383C15.5691 5.94058 15.9609 5.54883 16.4441 5.54883C16.9274 5.54883 17.3191 5.94058 17.3191 6.42383V13.6349C17.3191 14.3393 17.3191 14.9206 17.2805 15.3942C17.2403 15.886 17.154 16.3381 16.9376 16.7628C16.6021 17.4214 16.0667 17.9568 15.4081 18.2923C14.9834 18.5087 14.5313 18.595 14.0395 18.6352C13.5659 18.6738 12.9846 18.6738 12.2802 18.6738H10.108C9.40365 18.6738 8.82233 18.6738 8.34878 18.6352C7.85694 18.595 7.40482 18.5087 6.98016 18.2923C6.32159 17.9568 5.78616 17.4214 5.4506 16.7628C5.23422 16.3381 5.14798 15.886 5.10779 15.3942C5.0691 14.9206 5.06911 14.3393 5.06912 13.635L5.06912 6.42383C5.06912 5.94058 5.46087 5.54883 5.94412 5.54883Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1941 13.4238C10.7109 13.4238 10.3191 13.8156 10.3191 14.2988V16.9238H8.56912V14.2988C8.56912 12.8491 9.74437 11.6738 11.1941 11.6738C12.6439 11.6738 13.8191 12.8491 13.8191 14.2988V16.9238H12.0691V14.2988C12.0691 13.8156 11.6774 13.4238 11.1941 13.4238Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.41148 7.69013C3.62759 8.12236 4.15318 8.29756 4.58541 8.08144L11.1941 4.7771L17.8028 8.08144C18.235 8.29756 18.7606 8.12236 18.9767 7.69013C19.1928 7.2579 19.0176 6.73231 18.5854 6.5162L11.5854 3.0162C11.3391 2.89303 11.0491 2.89303 10.8028 3.0162L3.80279 6.5162C3.37056 6.73231 3.19536 7.2579 3.41148 7.69013Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Home',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
