<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2348 4.2266C13.6017 2.85975 15.8178 2.85977 17.1846 4.22666L17.8474 4.88945C19.2142 6.2563 19.2141 8.47236 17.8473 9.83916L10.3668 17.3195C9.93152 17.7548 9.38918 18.0675 8.79447 18.2263L6.42967 18.8574C4.47954 19.3779 2.69611 17.5945 3.21651 15.6444L3.8476 13.2795C4.00632 12.6847 4.3191 12.1423 4.75439 11.707L12.2348 4.2266ZM15.9472 5.46407C15.2637 4.78062 14.1557 4.78061 13.4723 5.46404L12.6748 6.26149L15.8124 9.39913L16.6099 8.60171C17.2933 7.91831 17.2933 6.81028 16.6099 6.12686L15.9472 5.46407ZM7.4248 11.5115L11.4374 7.49893L14.575 10.6366L10.5624 14.6491L7.4248 11.5115ZM6.18736 12.7489L5.99182 12.9445C5.77418 13.1621 5.61779 13.4333 5.53843 13.7307L4.90735 16.0956C4.73388 16.7456 5.32836 17.3401 5.9784 17.1666L8.3432 16.5354C8.64056 16.4561 8.91172 16.2997 9.12935 16.0821L9.32493 15.8865L6.18736 12.7489Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Editor',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
