<template>
  <div class="flex flex-col items-center nav" id="navbar" ref="navbar">
    <img
      class=" mt-5.5 inline-block"
      src="../../../assets/icons/BrandRed.svg"
    />

    <!-- <IconButton
      classname="mt-5.5 group"
      @click="showQueueModal"
      v-tooltip.right="'Notifications'"
      :variant="showNotifications ? 'active' : 'normal'"
    >
      <div class="relative">
        <BellIcon class="w-5 h-5 group-hover:text-red" />
        <div
          v-show="newPodcast"
          class="bg-red rounded-full h-2 top-0.25 right-0.25 w-2 absolute"
        />
      </div>
    </IconButton> -->

    <a
      class="mt-4.5"
      :href="
        isDisabled
          ? 'javascript:void(0);'
          : `https://${isProd ? 'publish.' : 'public.'}spext.co/library`
      "
    >
      <IconButton
        classname="group"
        v-tooltip.right="'Your Library'"
        :variant="isDisabled ? 'disabled' : 'normal'"
      >
        <Home
          :classname="`w-5 h-5 ${isDisabled ? '' : 'group-hover:text-red'}`"
        />
      </IconButton>
    </a>

    <IconButton
      classname="mt-4.5 group"
      v-tooltip.right="'Editor Studio'"
      :variant="
        isDisabled ? 'disabled' : currentRoute === 'drive' ? 'active' : 'normal'
      "
      @click="goToDrive"
    >
      <Editor
        :classname="`w-5 h-5 ${isDisabled ? '' : 'group-hover:text-red'}`"
      />
    </IconButton>

    <a
      class="mt-4.5"
      :href="
        isDisabled
          ? 'javascript:void(0);'
          : `https://calendar.${isProd ? '' : 'dev.'}spext.co/auth`
      "
    >
      <IconButton
        classname="group"
        v-tooltip.right="'Meetings Dashboard '"
        :variant="isDisabled ? 'disabled' : 'normal'"
      >
        <Calendar
          :classname="`w-5 h-5 ${isDisabled ? '' : 'group-hover:text-red'}`"
        />
      </IconButton>
    </a>

    <IconButton
      classname="mt-4.5 group"
      v-tooltip.right="'Brand Kit'"
      :variant="
        isDisabled
          ? 'disabled'
          : currentRoute === 'brandkit'
          ? 'active'
          : 'normal'
      "
      @click="goToBrandkit"
    >
      <BrandKit
        :classname="`w-5 h-5 ${isDisabled ? '' : 'group-hover:text-red'}`"
      />
    </IconButton>

    <!-- <Popover
      offset="6"
      placement="right-start"
      popoverArrowClass="hidden"
      @show="openPopup='updates';"
      @hide="openPopup='';"
    >
      <IconButton
        classname="mt-4.5 group"
        v-tooltip.right="openPopup === 'updates' ? '' : 'Updates'"
      >
        <GiftIcon class="w-5 h-5 group-hover:text-red" />
      </IconButton>

      <template slot="popover">
        <div class="mt-5.5 p-4.5 updates relative">
          <div
            class="cursor-pointer top-4.5 right-4.5 text-primary-500 absolute hover:text-primary-700"
            v-close-popover
          >
            <CloseIcon class="w-5 h-5" />
          </div>

          <div class="text-2xl font-semibold font-h text-primary">New Updates</div>

          <div class="mt-4 text-base text-primary">
            Spext 4.0: Like Google docs, but for audio editing 🎉
          </div>

          <div class="mt-4 text-sm leading-relaxed pr-14 text-primary-700">
            Spext is updated to v4.0 and now supports real time collaboration & multiuser editing, which means you can work on your files with your team. We have made it as easy as sharing a file in “Google Docs” and works for both, transcript & audio editing.
          </div>

          <div class="mt-8 text-base font-semibold text-primary">
            Key Features:
          </div>

          <div class="pr-4 mt-4 text-sm leading-relaxed text-primary-700">
            <b>Commenting:</b> People can comment on particular sections to give feedback.
            <br/>
            <br/>
            <b>Editing:</b> Multiple people can edit a file simultaneously and all changes are reflected in real time.
          </div>
        </div>
      </template>
    </Popover> -->

    <Popover
      offset="6"
      placement="right-start"
      popoverClass="popover-sm"
      popoverArrowClass="hidden"
      @show="
        () => {
          isDisabled ? () => {} : (openPopup = 'help');
        }
      "
      @hide="openPopup = ''"
    >
      <IconButton
        classname="mt-4.5 group"
        v-tooltip.right="openPopup === 'help' ? '' : 'Help'"
        :variant="isDisabled ? 'disabled' : 'normal'"
      >
        <More
          :classname="`w-5 h-5 ${isDisabled ? '' : 'group-hover:text-red'}`"
        />
      </IconButton>

      <template slot="popover">
        <div v-show="!isDisabled" class="mt-4.5 px-4 py-5 rounded-3">
          <div
            v-for="(option, i) in helpDropdownOptions"
            :key="i"
            class="pr-4 text-sm cursor-pointer text-primary"
            :class="[
              i + 1 === helpDropdownOptions.length ? '' : ' pb-2 mb-3 border-b'
            ]"
            v-close-popover
            @click="helpSelected(option.choice)"
          >
            {{ option.title }}
          </div>
        </div>
      </template>
    </Popover>

    <div class="flex-grow" />
    <IconButton
      v-if="isEditor"
      v-show="!clipper && !isDisabled"
      classname="mb-1"
      @click="openModal({ name: 'InviteModal' })"
      v-tooltip.right="'Invite Collaborators'"
      variant="normal"
    >
      <AddIcon class="w-5 h-5" />
    </IconButton>

    <IconButton
      v-else-if="isDrive"
      classname="mb-1 group"
      @click="selectedProfileOption({ title: 'Admin Control' })"
      v-tooltip.right="'Invite'"
    >
      <AddIcon class="w-5 h-5 group-hover:text-red" />
    </IconButton>

    <div class="mt-1 mb-4 border-t border-primary-400 w-7" />

    <Popover
      v-if="isLoggedIn"
      offset="6"
      placement="right-end"
      popoverClass="popover-sm"
      popoverArrowClass="hidden"
      @show="openPopup = 'profile'"
      @hide="openPopup = ''"
    >
      <div
        class="flex items-center justify-center w-8 h-8 mb-4 border-2 rounded-full cursor-pointer border-red"
        v-tooltip.bottom="openPopup === 'profile' ? '' : 'Account Details'"
      >
        <Avatar :size="28" :src="user.photoURL" :username="user.displayName" />
      </div>

      <template slot="popover">
        <div class="px-4 py-5">
          <div
            v-for="(option, i) in profileDropdownOptions"
            :key="i"
            class="pr-4 text-sm cursor-pointer text-primary"
            :class="[
              i + 1 === profileDropdownOptions.length
                ? ''
                : 'pb-2 mb-3 border-b'
            ]"
            v-close-popover
            @click="selectedProfileOption(option)"
          >
            {{ option.title }}
          </div>
        </div>
      </template>
    </Popover>
    <login-button v-else v-show="showLogin" :isDisabled="isDisabled" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '@/services/store'
import Avatar from 'vue-avatar'
import IconButton from '@/components/base/buttons/IconButton'
import AddIcon from '@/components/base/icons/Add.vue'

import Popover from '@/components/base/display/Popover.vue'

import CONSTANTS from '../../../constants/CONSTANTS'
import Home from '@/components/base/icons/Home.vue'
import More from '@/components/base/icons/More.vue'
import BrandKit from '@/components/base/icons/Brandkit.vue'
import Editor from '@/components/base/icons/Editor.vue'
import Calendar from '@/components/base/icons/Calendar.vue'
import LoginButton from '@/components/LandingPage/LoginButton.vue'
export default {
  name: 'Header',
  props: {
    clipper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openPopup: ''
    }
  },
  components: {
    AddIcon,
    IconButton,
    Avatar,
    Popover,
    Home,
    More,
    BrandKit,
    Editor,
    Calendar,
    LoginButton
  },
  computed: {
    ...mapGetters({
      newPodcast: 'app/newPodcast',
      showNotifications: 'modal/showNotifications',
      openModalName: 'dialogs/modal',
      billing: 'app/billing',
      user: 'app/user',
      unifiedAuthLoading: 'app/unifiedAuthLoading'
    }),
    showCreate() {
      if (
        this.userInfo &&
        this.userInfo.billing &&
        ((this.userInfo.billing.subscribed &&
          CONSTANTS.VOICE_PLANS.indexOf(this.userInfo.billing.subscribed) >=
            0) ||
          this.userInfo.billing.trial)
      ) {
        return true
      }
      return false
    },
    userInfo() {
      return store.state.app.userInfo
    },
    computedList() {
      return store.state.app.computedList
    },
    uploadingList() {
      return store.state.app.uploadingList
    },
    currentRoute: function() {
      return this.$parent.$route.name
    },
    isProd() {
      return process.env.NODE_ENV === 'production'
    },
    helpDropdownOptions() {
      let hdo = [
        {
          title: 'Notifications',
          choice: 'notifications'
        },
        {
          title: 'Pricing',
          choice: 'pricing'
        }
        // {
        //   title: 'FAQs',
        //   choice: 'faq'
        // },
        // {
        //   title: 'Tutorial Videos',
        //   choice: 'tutorialVideos'
        // }
      ]
      if (this.$parent.$router.currentRoute.name === 'drive') {
        hdo.push({
          title: 'Try Sample File',
          choice: 'sampleFile'
        })
      }
      if (this.$parent.$router.currentRoute.name === 'HomeProse') {
        hdo.push({
          title: 'Walkthrough',
          choice: 'aboutMedia'
        })
      }
      return hdo
    },
    isEditor() {
      return this.$parent.$router.currentRoute.name === 'HomeProse'
    },
    isDrive() {
      return this.$parent.$router.currentRoute.name === 'drive'
    },
    profileDropdownOptions() {
      if (this.isDisabled) {
        return [
          {
            title: 'Login'
          }
        ]
      }
      return [
        {
          title: 'Account Details'
        },
        {
          title: 'Logout'
        }
      ]
    },
    isLoggedIn() {
      return (this.user && this.user.uid && !this.user.isAnonymous)
    },
    isDisabled() {
      return !this.isLoggedIn || this.unifiedAuthLoading
    },
    showLogin() {
      return this.$router.currentRoute.name !== 'index'
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      logout: 'app/logout'
    }),
    createNewDoc() {
      store.dispatch('app/createNewDoc')
    },
    showUploadModal() {
      this.$emit('showUploadModal')
    },
    showBillingModal(tab) {
      this.openModal({ name: 'AccountInfo', props: { tab } })
    },
    showQueueModal() {
      store.dispatch('modal/showQueueModal')
    },
    helpSelected(choice) {
      switch (choice) {
        case 'faq':
          this.showFAQ()
          break
        case 'tutorialVideos':
          this.showTutorialVideos()
          break
        case 'firstPreference':
          this.openKnowPurposePopover()
          break
        case 'sampleFile':
          this.openModal({ name: 'EditorModal' })
          break
        case 'notifications':
          this.showQueueModal()
          break
        case 'pricing':
          this.showBillingModal('Plans')
          break
        default:
          this.$emit('helpSelected', choice)
      }
    },
    showFAQ() {
      window.analytics.track('sidebar_faqClicked')
      window.open('http://help.spext.co/faqs', '_blank')
    },
    showTutorialVideos() {
      window.analytics.track('sidebar_tutorialClicked')
      window.open(
        'http://help.spext.co/en/collections/1872349-step-by-step-video-tutorials',
        '_blank'
      )
    },
    openKnowPurposePopover() {
      this.openModal({ name: 'KnowPurpose' })
    },
    selectedProfileOption(choice) {
      if (choice.title === 'Account Details') {
        this.openModal({ name: 'AccountInfo' })
      } else if (choice.title === 'Admin Control') {
        this.openModal({ name: 'AdminControl' })
      } else if (choice.title === 'Logout') {
        this.logout()
      } else if (choice.title === 'Login') {
        this.$router.push('/')
      }
    },
    goToDrive: function() {
      if (this.isDisabled) return
      this.$parent.$router.push('/drive')
    },
    goToBrandkit: function() {
      if (this.isDisabled) return
      this.$parent.$router.push('/brandkit')
    }
  }
}
</script>

<style lang="scss" scoped>
#navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 60px;
  min-width: 60px;
  z-index: 10;
  background-color: #fff;
  box-shadow: inset -1px 0px 0px #e5e7eb;
}

.updates {
  width: 436px;
}
</style>
